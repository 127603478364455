<template>
    <div v-if="goodDetail" class="good-detail">
        <div class="banner">
            <div class="banner-main" @click="openImageView()">
                <swiper ref="swiperMain" :options="swiperMainOption">
                    <swiper-slide v-for="(item,index) in goodDetail.thumb_arr" :key="index">
                        <a :style="`background: url('${item}')`"></a>
                    </swiper-slide>
                    <div slot="pagination" class="swiper-pagination"></div>
                </swiper>
            </div>
            <div v-if="goodDetail.thumb_arr&&goodDetail.thumb_arr.length>2" class="banner-secondary">
                <swiper ref="swiperSecondary" :options="swiperSecondaryOption">
                    <swiper-slide v-for="(item,index) in goodDetail.thumb_arr" :key="index">
                        <a :style="`background: url('${item}')`"></a>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <div class="good-info">
            <div class="info-top">
                <div class="item-word">
                    <p>
                        <b>产品名称：</b>
                        <b>{{ goodDetail.title }}</b>
                    </p>
                    <p>
                        <b>价<i class="transparent">占占</i>格：</b>
                        <b class="red" v-if="goodDetail.price">
                            <template v-if="goodDetail.children&&goodDetail.children.length>0">￥{{ minPrice }} ~ {{ maxPrice }}</template>
                            <template v-else>￥{{goodDetail.price}}</template>
                        </b>
                        <b v-else class="red">暂无报价</b>
                        <span class="grey">{{ goodDetail.subtitle }}</span>
                    </p>
                </div>
                <a v-if="app.userData.createOrder" class="item-button" @click="openChooseDialog()">加入购物车</a>
            </div>
            <div class="info-bottom">
                <p v-for="(item,index) in  goodDetail.parameter_form" :key="index">
                    <b>{{ item.name }}：</b>
                    <b>{{ item.value }}</b>
                </p>
            </div>
        </div>
        <div v-if="goodDetail.children&&goodDetail.children.length>0" class="good-richtext">
            <p class="item-title">产品列表</p>
            <div class="item-table">
                <p class="table-title">
                    <span>名称</span>
                    <span>规格</span>
                    <span>单价</span>
                </p>
                <p v-for="(item,index) in goodDetail.children" class="table-content">
                    <span>{{ item.title }}</span>
                    <template v-for="(citem,cindex) in item.parameter_form">
                        <span v-if="citem.name==='规格'">{{citem.value}}</span>
                    </template>
                    <span>￥{{ item.price }}</span>
                </p>
            </div>
        </div>
        <div class="good-richtext">
            <p class="item-title">产品信息</p>
            <div class="item-content" v-html="goodDetail.content"></div>
        </div>
        <!--选择商品规格弹窗-->
        <van-dialog v-model="chooseDialog.visible" :closeOnClickOverlay="true" :showConfirmButton="false">
            <div class="choose-dialog">
                <div class="item-top">
                    <p class="p1">请选择你要的商品：</p>
                    <p class="p2">{{ goodDetail.bar_code }}</p>
                    <p class="p3">
                        <span>选择</span>
                        <span class="red">￥{{ totalPrice }}</span>
                    </p>
                </div>
                <div class="item-middle">
                    <div class="list-wrapper">
                        <div v-for="(item,index) in goodDetail.children" v-if="item.on_sale===1" class="list-item">
                            <a class="item-name">{{ item.sub_title }}</a>
                            <div class="item-count">
                                <van-stepper v-model="item.count" min="0" @change="countChange()"/>
                            </div>
                            <p class="item-price">￥{{ item.price }}</p>
                        </div>
                    </div>
                </div>
                <div class="item-bottom">
                    <van-button block class="item-button" color="#F54A4A" type="primary" @click="addGood()">加入购物车
                    </van-button>
                </div>
            </div>
        </van-dialog>
        <!--成功加入购物车弹窗-->
        <van-dialog v-model="addSuccessDialog.visible" :closeOnClickOverlay="true" :showConfirmButton="false">
            <div class="add-success-dialog">
                <div :style="`background: url('${goodDetail.thumb}')`" class="item-image"></div>
                <div class="item-title">
                    <p class="title-left">
                        {{ goodDetail.title }}
                    </p>
                    <p class="title-right">
                        <van-icon name="checked"/>
                        <span>已加入购物车</span>
                    </p>
                </div>
                <div class="item-info">
                    <p>
                        <span>小计：</span>
                        <span class="s1">{{ goodDetail.price }}元</span>
                    </p>
                </div>
                <div class="item-button">
                    <van-button block class="item-button" color="#F54A4A" to="/shop-cart" type="primary">去购物车结算
                    </van-button>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
// 引入插件
import {swiper, swiperSlide} from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
// 引入mixins
import mixins_config from "@/mixins/config";
// 引入接口
import {ADD_CARTS, GOODS_DETAIL} from "@/api/dataProvider.js"
// 引入图片预览
import {ImagePreview} from "vant";

// 给swiper用
let vm = null;

export default {
    name: "GoodDetail",
    mixins: [mixins_config],
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            // 商品详情
            goodDetail: {},
            // 总价
            totalPrice: 0,
            minPrice: 0,
            maxPrice: 0,
            // 轮播图
            swiperMainOption: {
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                },
                on: {
                    slideChange: function () {
                        vm.$refs.swiperSecondary.swiper.slideTo(this.activeIndex, 500, false)
                    }
                }
            },
            swiperSecondaryOption: {
                slidesPerView: 3,
                spaceBetween: 10,
                slideToClickedSlide: true,
                on: {
                    click: function () {
                        vm.$refs.swiperMain.swiper.slideTo(this.clickedIndex, 500, false)
                    }
                }
            },
            // 选择单品弹窗
            chooseDialog: {
                visible: false
            },
            // 成功加入购物车弹窗
            addSuccessDialog: {
                visible: false
            },
        };
    },
    created() {
        vm = this;
        this.app.tabActive = 1
    },
    mounted() {
        this.getPid().then(() => {
            this.getGoodDetail()
        })
    },
    methods: {
        // 打开图片预览
        openImageView() {
            ImagePreview({
                images: this.goodDetail.thumb_arr,
                closeable: true,
            })
        },
        // 获取父商品PID
        getPid() {
            return new Promise((resolve) => {
                GOODS_DETAIL(
                    {
                        id: this.$route.params.id
                    },
                    (res) => {
                        if (res.data.data.data.pid > 0) {
                            // 子类跳父类
                            // this.$router.push({
                            //     name: "GoodDetail",
                            //     params: {
                            //         id: res.data.data.data.pid
                            //     }
                            // })
                        }
                        resolve()
                    }
                )
            })
        },
        // 获取商品列表
        getGoodDetail() {
            GOODS_DETAIL(
                {
                    id: this.$route.params.id
                },
                (res) => {
                    this.goodDetail = res.data.data.data
                    if (this.goodDetail.children && this.goodDetail.children.length > 0) {
                        let priceArr = []
                        let imgArr = []
                        this.goodDetail.children.map((item) => {
                            item.count = 0
                            priceArr.push(item.price)
                            imgArr = imgArr.concat(item.thumb_arr)
                        })
                        this.goodDetail.thumb_arr = this.goodDetail.thumb_arr.concat(imgArr)
                        console.log(this.goodDetail.thumb_arr)
                        this.minPrice = Math.min(...priceArr)
                        this.maxPrice = Math.max(...priceArr)
                    }
                }
            )
        },
        // 打开选择单品弹窗
        openChooseDialog() {
            if (this.goodDetail.children && this.goodDetail.children.length > 0) {
                this.chooseDialog.visible = true
            } else {
                ADD_CARTS(
                    {
                        cargos: [
                            {
                                id: this.goodDetail.id,
                                count: 1
                            }
                        ]
                    },
                    (res) => {
                        if (res.data.code == 0) {
                            this.$notify({type: "success", message: "加入成功！"});
                            this.getGoodDetail()
                        } else {
                            this.$notify({type: "danger", message: "加入失败，请重试！"});
                        }
                    }
                )
            }
        },
        // 获取弹窗价格
        getAllPrice(arr) {
            let price = 0
            arr.map((item) => {
                price += Number(item.price * item.count)
            })
            this.totalPrice = price
        },
        // 更新价格
        countChange() {
            let priceArr = this.goodDetail.children.filter((item) => {
                return item.count > 0
            })
            this.getAllPrice(priceArr)
        },
        // 添加到购物车
        addGood() {
            let goodsArr = []

            this.goodDetail.children.map((item) => {
                if (item.count > 0) {
                    goodsArr.push(
                        {
                            id: item.id,
                            count: item.count
                        }
                    )
                }
            })

            this.chooseDialog.visible = false

            ADD_CARTS(
                {
                    cargos: goodsArr
                },
                (res) => {
                    if (res.data.code == 0) {
                        this.$notify({type: "success", message: "加入成功！"});
                        this.getGoodDetail()
                    } else {
                        this.$notify({type: "danger", message: "加入失败，请重试！"});
                    }
                }
            )
        },
    }
}
</script>

<style lang="scss">
.good-detail {
    padding: 0 0 50px 0;

    .banner {
        .banner-main {
            width: 100%;
            height: 264px;

            .swiper-container {
                width: 100%;
                height: 100%;

                .swiper-wrapper {
                    width: 100%;
                    height: 100%;

                    .swiper-slide {
                        width: 100%;
                        height: 100%;

                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            background-repeat: no-repeat !important;
                            background-size: contain !important;
                            background-position: center !important;
                        }
                    }
                }
            }
        }

        .banner-secondary {
            width: calc(100% - 40px);
            height: 70px;
            margin: 20px auto 0 auto;

            .swiper-container {
                width: 100%;
                height: 100%;

                .swiper-wrapper {
                    width: 100%;
                    height: 100%;

                    .swiper-slide {
                        a {
                            display: block;
                            width: 100%;
                            height: 60px;
                            background-repeat: no-repeat !important;
                            background-size: cover !important;
                            background-position: center !important;
                        }
                    }
                }
            }
        }
    }

    .good-info {
        padding: 20px;

        .info-top {
            display: flex;
            align-items: center;

            .item-word {
                width: 250px;

                p {
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    b, span {
                        margin-right: 10px;
                        font-size: 10px;
                        color: #2D2D2D;
                    }

                    .transparent {
                        opacity: 0;
                    }

                    .red {
                        color: #F54A4A;
                    }

                    .grey {
                        color: #999999;
                    }
                }
            }

            .item-button {
                width: 69px;
                height: 28px;
                line-height: 28px;
                margin-left: auto;
                background: #F54A4A;
                border-radius: 3px;
                text-align: center;
                font-size: 10px;
                color: #fff;
            }
        }

        .info-bottom {
            margin: 20px 0 0 0;

            p {
                padding: 10px;
                background: #EEEEEE;

                &:nth-child(2n) {
                    background: transparent;
                }

                b {
                    margin-right: 10px;
                    font-size: 10px;
                    color: #2D2D2D;
                }

                .transparent {
                    opacity: 0;
                }
            }
        }
    }

    .good-richtext {
        padding: 0 20px 20px 20px;

        .item-title {
            line-height: 28px;
            padding-left: 15px;
            font-size: 14px;
            font-weight: bold;
            color: #2D2D2D;
            border-left: 7px solid #F96719;
        }

        .item-content {
            margin-top: 20px;

            p {
                margin-bottom: 15px;
                font-size: 10px;
                color: #2D2D2D;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .item-table {
            margin-top: 20px;

            .table-title {
                display: flex;
                align-items: center;
                line-height: 35px;
                background: #F96719;

                span {
                    flex: 1;
                    text-align: center;
                    font-size: 12px;
                    color: #FFFFFF;
                }
            }

            .table-content {
                display: flex;
                align-items: center;
                line-height: 35px;

                span {
                    flex: 1;
                    text-align: center;
                    font-size: 12px;
                    color: #333333;
                }
            }
        }
    }

    .choose-dialog {
        padding: 14px 0;

        .item-top {
            margin: 0 14px;
            padding: 0 0 7px 0;
            border-bottom: 1px solid #EEEEEE;

            .p1 {
                font-size: 10px;
                color: #2D2D2D;
            }

            .p2 {
                margin-top: 20px;
                font-size: 14px;
                font-weight: bold;
                color: #2D2D2D;
            }

            .p3 {
                margin-top: 20px;
                display: flex;
                align-items: center;

                span {
                    font-size: 10px;
                    font-weight: bold;
                    color: #2D2D2D;
                }

                .red {
                    margin-left: auto;
                    color: #F54A4A;
                }
            }
        }

        .item-middle {
            padding: 0 14px;
            height: 300px;
            overflow-y: scroll;

            .list-wrapper {
                .list-item {
                    display: flex;
                    align-items: center;
                    margin-top: 14px;

                    .item-name {
                        max-width: 130px;
                        margin-right: auto;
                        padding: 5px 14px;
                        font-size: 10px;
                        color: #999999;
                        background: #EEEEEE;
                        border-radius: 3px;
                    }

                    .item-count {
                        .van-stepper {
                            button {
                                width: 20px;
                                height: 23px;
                            }

                            input {
                                width: 30px;
                                height: 23px;
                            }
                        }
                    }

                    .item-price {
                        width: 70px;
                        text-align: right;
                        font-size: 10px;
                        color: #999999;
                    }
                }
            }
        }

        .item-bottom {
            margin: 14px 14px 0 14px;
            padding: 14px 0 0 0;
            border-top: 1px solid #eee;
        }
    }

    .add-success-dialog {
        padding: 14px;

        .item-image {
            width: 100%;
            height: 170px;
            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position: center !important;
            border-radius: 3px;
        }

        .item-title {
            display: flex;
            align-items: center;
            margin-top: 14px;

            .title-left {
                line-height: 25px;
                font-size: 14px;
                font-weight: bold;
                color: #2D2D2D;
            }

            .title-right {
                min-width: 110px;
                margin-left: auto;
                text-align: right;

                i {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 20px;
                    color: #88CF65
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 3px;
                    font-size: 14px;
                    color: #88CF65
                }
            }
        }

        .item-info {
            margin: 10px 0 0 0;

            p {
                line-height: 20px;
                display: flex;

                span {
                    font-size: 10px;
                    font-weight: bold;
                    color: #2D2D2D;
                }

                .s1 {
                    margin-left: auto;
                    color: #F54A4A;
                }

                .s2 {
                    margin-left: auto;
                    color: #F96719;
                }
            }
        }

        .item-button {
            margin: 14px 0 0 0;
        }
    }

    .van-dialog {
        border-radius: 5px;
    }
}
</style>
